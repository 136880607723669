<template>
    <div class="container">
        <PageHeader title="검색할 날짜를 선택하세요." subtitle="의뢰인이 탈퇴한 경우 조회되지 않을 수 있어요." />

        <tag-box>
            <li v-for="(row, key) in yearArr" :key="key" :class="{ on: row.isActive }" @click="row.onClick">
                {{ row.label }}
            </li>
        </tag-box>

        <div class="ui-border-line ui-mt-3 ui-mb-5 mx-0" style="background-color: #979797" />

        <tag-box>
            <li v-for="(row, key) in monthArr" :key="key" :class="{ on: row.isActive }" @click="row.onClick">
                {{ row.label }}
            </li>
        </tag-box>

        <FooterBox submitText="검색하기" :submitCb="submitDatePicker" :submitDisabled="!isValidDatePicker" />
    </div>
</template>

<script>
import PageHeader from '@/components/common/PageHeader.vue'
import FooterBox from '@/components/common/FooterBox.vue'
import TagBox from '@/components/common/TagBox.vue'

export default {
    name: 'ArtistMyPaidOfferFilter',
    components: {
        PageHeader,
        FooterBox,
        TagBox,
    },
    data() {
        return {
            datePicker: {
                year: undefined,
                month: undefined,
            },
        }
    },
    computed: {
        yearArr() {
            const currentYear = new Date().getFullYear()
            const arr = Array(4)
                .fill()
                .map((_, k) => {
                    const value = currentYear - k
                    return {
                        label: `${value}년`,
                        value,
                        isActive: value === this.datePicker.year,
                        onClick: () => {
                            this.datePicker.year = value
                        },
                    }
                })
            arr.push({
                label: '전체 기간',
                value: undefined,
                isActive: !this.datePicker.year && !this.datePicker.month,
                onClick: () => {
                    this.datePicker.year = undefined
                    this.datePicker.month = undefined
                },
            })
            return arr
        },
        monthArr() {
            return Array(12)
                .fill()
                .map((_, k) => {
                    const value = 1 + k
                    return {
                        label: `${value}월`,
                        value,
                        isActive: value === this.datePicker.month,
                        onClick: () => {
                            this.datePicker.month = value
                        },
                    }
                })
        },
        isValidDatePicker() {
            const { year, month } = this.datePicker

            return (year && month) || (!year && !month)
        },
    },
    created() {
        if (this.$route.query.payDate) {
            const year = Number(this.$route.query.payDate?.slice?.(0, 4)) || undefined
            const month = Number(this.$route.query.payDate?.slice?.(4)) || undefined

            this.datePicker.year = year
            this.datePicker.month = month
        }
    },
    methods: {
        submitDatePicker() {
            const now = new Date()
            const currentYear = now.getFullYear()
            const currentMonth = now.getMonth() + 1
            const { year, month } = this.datePicker
            let payDate: string | undefined

            if (year && month) {
                payDate = `${year}${String(month).padStart(2, '0')}`

                if (currentYear === year && currentMonth < month) {
                    this.$toast('미래의 날짜는 조회할 수 없어요.')
                    return
                }
            }

            this.$router.replace({
                path: '/artist/payment',
                query: {
                    payDate,
                },
            })
        },
    },
}
</script>
